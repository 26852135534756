.pagination {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  /* width: 10px; */
  /* overflow-x: scroll; */
}

.paginationButton {
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: grey;
  cursor: pointer;
  border-radius: 10px;
  min-width: 40px;
}

.paginationButton:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
  opacity: 0.6;
}

.activePage {
  background-color: #ffc107;
  color: white;
  border-color: #ffc107;
  cursor: pointer;
  border-radius: 10px;
  min-width: 40px;
  padding: 8px 12px;
}

.paginationEllipsis {
  padding: 8px;
  color: #666;
}

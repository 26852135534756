.dataTableContainer {
  width: 100%;
  overflow-x: auto;
  /* margin: 20px 0; */
}

.dataTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  background: #212529;
}

.dataTable th,
.dataTable td {
  padding: 12px;
  text-align: right;
  border: 1px solid #ddd;
  border-bottom: 2px solid #dee2e6;
  color: #ffc107;
}

.dataTable th {
  background-color: #212529;
  font-weight: 600;
  position: sticky;
  top: 0;
}

.dataTable td:first-child,
.dataTable th:first-child {
  text-align: left;
  background-color: #212529;
  position: sticky;
  left: 0;
  z-index: 1;
}

.dataTable tr:hover {
  background-color: #212529;
}

.dataTable .grandTotalRow {
  font-weight: bold;
  background-color: #212529;
}

.dataTable .numberCell {
  font-family: monospace;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
  margin: 1rem 0;
  z-index: 10;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #212529;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.tableHeader {
  background-color: #212529;
  border-bottom: 2px solid #dee2e6;
}

.tableHeader th {
  padding: 12px 16px;
  text-align: left;
  font-weight: 600;
  color: #ffffff;
  white-space: nowrap;
}

.tableBody td {
  padding: 12px 16px;
  border-bottom: 1px solid #dee2e6;
  color: #ffc107;
}

/* .tableBody tr:last-child td {
  border-bottom: none;
} */

.tableBody > tr:last-child > td {
  border-bottom: none;
  /* Applies only to the last row of the main table */
}

.tableBody tr td {
  border-bottom: 1px solid #dee2e6;
  /* Ensures borders for all rows */
}

.tableBody tr:hover {
  background-color: #212529;
}

.emptyCell {
  color: #ffc107;
  font-style: italic;
}

.tableInput {
  width: 90%;
  height: 30px;
  border-radius: 3px;
  padding-left: 5px;
  margin-top: 3%;
  border: 1px solid white;
  margin-bottom: 3%;
  font-size: 15px;
  background-color: #212529;
  /* font-family: 'Inter', sans-serif; */
  /* font-family: "Neue Haas Grotesk Display Pro"; */
  /* max-width: 700px; */
  color: white;
  font-weight: 500;
}

.actionButton {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding-left: 5px;
  margin-top: 3%;
  border: 1px solid #ffc107;
  margin-bottom: 3%;
  font-size: 15px;
  background-color: #212529;
  color: #ffc107;
  font-weight: 500;
}

.actionButton2 {
  width: 90%;
  height: 30px;
  border-radius: 5px;
  padding-left: 5px;
  margin-top: 5%;
  border: 1px solid #ffc107;
  margin-bottom: 3%;
  font-size: 15px;
  background-color: #212529;
  color: #ffc107;
  font-weight: 500;
}

.actionButton3 {
  /* width: 4%; */
  height: 30px;
  border-radius: 5px;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3px;
  margin-right: 2rem;
  margin-top: 3%;
  border: 1px solid #ffc107;
  margin-bottom: 3%;
  font-size: 15px;
  background-color: #212529;
  color: #ffc107;
  font-weight: 500;
}

.tableInput ::placeholder {
  color: #ffffff;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tableInputHidden {
  height: 30px;
  border-radius: 3px;
  padding-left: 5px;
  margin-top: 3%;
  border: 1px solid #212529;
  margin-bottom: 3%;
  font-size: 15px;
  background-color: #212529;
  /* font-family: 'Inter', sans-serif; */
  /* font-family: "Neue Haas Grotesk Display Pro"; */
  /* max-width: 700px; */
  color: #212529;
  font-weight: 500;
}

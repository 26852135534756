.nestedTable {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  background-color: #212529;
}

.nestedTable th,
.nestedTable td {
  border: 2px solid #ddd;
  padding: 8px;
  text-align: left;
  color: #ffc107;
}

.nestedTable th {
  background-color: #212529;
  font-weight: bold;
  color: #ffffff;
}

.tableRow {
  cursor: pointer;
}

.tableRow:hover {
  background-color: #f5f5f5;
}

.tableRow.expanded {
  background-color: #e9e9e9;
}

.indent {
  display: inline-block;
  width: 20px;
}

/* Level-specific styling */

.levelTableRow {
  background-color: #f8f8f8;
}

.level2 .tableRow {
  background-color: #f8f8f8;
}

.level3 .tableRow {
  background-color: #f0f0f0;
}

.level4 .tableRow {
  background-color: #e8e8e8;
}

.level5 .tableRow {
  background-color: #e0e0e0;
}

.level6 .tableRow {
  background-color: #d8d8d8;
}

.aboveMenu {
  bottom: 100%;
  position: absolute;
  top: auto;
}

.belowMenu {
  left: 0;
  position: absolute;
  right: 0;
  /* top: -250%; */
  background-color: #ffc107;
  z-index: 1000;
}
